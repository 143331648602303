<template>
    <zw-sidebar @shown="shown"
                :title="$t('reports.title.recalc_report')"
    >
        <b-overlay :show="loading" no-wrap></b-overlay>
        <validation-observer tag="div" ref="observer">
            <div v-if="!loading">
                <b-row>
                    <b-col>
                        <zw-new-date-group v-model="form.start_date"
                                           name="start_date"
                                           :label-prefix="labelPrefix"
                                           format="DD.MM.YYYY"
                                           @input="startDateChanged"
                        ></zw-new-date-group>
                    </b-col>
                    <b-col>
                        <zw-new-date-group v-model="form.end_date"
                                           name="end_date"
                                           :label-prefix="labelPrefix"
                                           format="DD.MM.YYYY"
                                           :minDate="form.start_date ? form.start_date : false"
                        ></zw-new-date-group>
                    </b-col>
                </b-row>

                <b-row>
                    <b-col sm="12" class="text-xs-center">
                        <b-row align-h="end">
                            <b-col sm="12" class="text-sm-right">
                                <b-button block @click="onSubmit()" variant="primary">
                                    {{ $t('common.button.save') }}
                                </b-button>
                            </b-col>
                        </b-row>
                    </b-col>
                </b-row>
            </div>
        </validation-observer>
    </zw-sidebar>

</template>

<script>
import commonSave from '@/bundles/erika_common_bundle/mixins/common-save'
import ZwNewDateGroup from "../../components/ZwNewDateGroup";
import moment from "moment";
import todos from "@/bundles/erika_common_bundle/mixins/todos";
import ZwDateSelectGroup from "@/components/ZwDateSelectGroup.vue";

export default {
    components: {ZwDateSelectGroup, ZwNewDateGroup},
    mixins: [commonSave, todos],
    data() {
        return {
            loading: true,
            payload: {},
            callback: null,
            labelPrefix: 'reports.label.',
            form: {
                report: null,
                start_date: moment().format('YYYY-MM-DD'),
                end_date: moment().format('YYYY-MM-DD'),
            }
        }
    },
    methods: {
        shown() {
            this.loading = false
            this.form.report = this.payload.report
        },
        onSubmit() {
            this.$refs['observer'].validate().then(valid => {
                if (valid) {
                    this.loading = true
                    window.axios.post(window.apiUrl + '/reports/recalculate', this.form)
                        .then((response) => {
                            this.commonAfterSave(response)
                        })
                        .catch(errors => {
                            this.$refs['observer'].setErrors(errors)
                            this.showValidationError()
                        })
                        .finally(() => {
                            this.loading = false
                        })
                } else {
                    this.commonValidation()
                }
            })
        },
        startDateChanged(val) {
            if (!this.form.end_date) {
                this.$set(this.form, 'end_date', val)
            }
        },
    }
}
</script>